import Empty from "@/components/Empty";
import NoDataText from "@/components/NoDataText";
import Return from "@/components/Return";
import useHandleScroll from "@/hooks/useHandleScroll";
import { formatTimeToStr } from "@/utils";
import useGetPrincipalHistoryByLimit from "@/web3Hooks/useGetPrincipalHistoryByLimit";
import { LoadingOutlined } from "@ant-design/icons";
import { formatUnits } from "ethers";
import { useTranslation } from "react-i18next";

const WithdrawalRecord = () => {
    const { dataPrincipalHistoryByLimit,
        pagesLinkProfits,
        principalHistoryByLimitLod,
        setHistoryLinkProfits
    } = useGetPrincipalHistoryByLimit()

    const { scrollableDivRef, handleScroll } = useHandleScroll(principalHistoryByLimitLod, setHistoryLinkProfits)
    const { t } = useTranslation();
    return (
        <div className="px-4">
            <Return text={t('withdrawalRecord.recordTitle')} /> {/* 使用 t 函数加载 "提取记录" */}
            <div ref={scrollableDivRef} onScroll={handleScroll} className="box-border  h-[calc(100vh-70px)] overflow-y-auto max-w-[1200px] mx-auto">
                {dataPrincipalHistoryByLimit.map((item, index) => (
                    <div key={index} className="flex items-center justify-between py-[15px] border-b border-[#31363F] last:border-none">
                        <p className="text-sm text-white">{formatTimeToStr(Number(formatUnits(item.time + '', 0)) * 1000)}</p>
                        <div className="text-sm font-medium text-[#E43D3D]">-{Number(formatUnits(item.amount + ''))}</div>
                    </div>
                ))}
                {/* 根据实际数据动态显示加载或无数据提示 */}
                {principalHistoryByLimitLod && <div className="text-white mt-3 text-center">{t('loading')} <LoadingOutlined /></div>}
                {!dataPrincipalHistoryByLimit.length && !principalHistoryByLimitLod && !Number(pagesLinkProfits) && <Empty />}
                {!Number(pagesLinkProfits) && !!dataPrincipalHistoryByLimit.length && (
                    <NoDataText />
                )}
            </div>
        </div>
    );
};

export default WithdrawalRecord;