import useIsRegister from "@/store/useIsRegister" // 导入注册状态管理
import useWallet from "@/store/useWallet" // 导入钱包状态管理
import { useCallback, useEffect, useState } from "react" // 导入 React 钩子
import { useLocation, useNavigate } from "react-router-dom" // 导入路由相关钩子

// 定义自定义 Hook，用于获取推荐人信息
const useHasReferrer = () => {
    const navigate = useNavigate() // 路由跳转方法
    const { setIsRegister, isRegister } = useIsRegister() // 获取注册状态和设置注册状态的方法
    const { pathname } = useLocation() // 获取当前路由路径
    const [userInfoLoading, setLoading] = useState(false) // 加载状态
    const [userInfo, setUserInfo] = useState({ // 用户信息状态
        referrer: '', // 推荐人地址
        start: 0, // 用户的开始时间
        teamNum: 0, // 用户团队人数
        totalDeposit: 0 // 用户的总存款
    })

    // 获取钱包地址和合约实例
    const { wallet: { address }, contract: { myStaking } } = useWallet()

    // 获取推荐人信息
    const getHasReferrerUserInfo = useCallback(async () => {
        setLoading(true)  // 设置加载状态为 true，表示开始加载
        try {
            if (myStaking) { // 检查合约是否存在
                // 通过合约调用获取用户信息
                const userInfo = await myStaking.userInfo(address)
                // 检查推荐人地址是否有效（非默认空地址）
                const is = userInfo.referrer !== '0x0000000000000000000000000000000000000000'

                // 如果注册状态改变，更新缓存中的注册状态
                if (isRegister !== is) {
                    setIsRegister(is) // 更新注册状态
                }

                // 如果用户已注册且当前页面为注册页面，则跳转到首页
                if (is && pathname === '/invite') {
                    navigate('/') // 跳转到首页
                }

                // 更新用户信息状态
                setUserInfo(userInfo)
            }
        } catch (e) {
            console.log('useHasReferrer', e)  // 捕获异常并打印错误信息
        }
        setLoading(false)  // 设置加载状态为 false，表示加载完成
    }, [myStaking, address, isRegister, pathname, setIsRegister, navigate])

    // 在组件挂载或依赖更新时调用获取推荐人信息的方法
    useEffect(() => {
        getHasReferrerUserInfo()
    }, [getHasReferrerUserInfo])

    // 返回推荐人信息及加载状态
    return { getHasReferrerUserInfo, userInfoLoading, userInfo }
}

export default useHasReferrer // 导出自定义 Hook