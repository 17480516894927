import { Outlet, useLocation } from 'react-router';
import Header from './component/Header';
import Foot from './component/Foot';
import { useEffect, useState } from 'react';
const pathData = ['/', '/my', '/invite']
const pathDataFoot = ['/', '/my']
const Layout = () => {
    const { pathname } = useLocation() // 获取当前路径以控制显示状态
    const [state, setState] = useState(false)
    const [stateFoot, setStateFoot] = useState(false)
    useEffect(() => {
        const index = pathData.findIndex(item => item === pathname)
        const index2 = pathDataFoot.findIndex(item => item === pathname)
        setStateFoot(index2 > -1)
        setState(index > -1)
    }, [pathname])
    return (<>
        {state && <Header />}
        <main className="app-main">
            <Outlet />
        </main>
        {stateFoot && <Foot />}
    </>);
};

export default Layout;
