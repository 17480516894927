import { useNavigate } from 'react-router-dom';
import left from '@/image/left.png'
const Return = (data: {
  path?: any;
  text: any;
}) => {
  const { path = -1, text } = data
  //初始化路由
  const navigate = useNavigate();
  // 路由跳转
  const go = () => {
    navigate(path)
  }
  return (
    <div className='box-border   h-[56px] flex items-center '>
      <div className=' w-full font-medium text-white text-base text-center relative' >
        {text}
        <img onClick={go} className='absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 cursor-pointer' src={left} alt="" />
      </div>
    </div>
  )
}

export default Return