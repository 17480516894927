import useWallet from "@/store/useWallet"
import { useCallback, useEffect, useState } from "react"

// 自定义 Hook：获取用户的代币余额
const useBalance = () => {
    const { wallet: { address }, contract: { erc20 } } = useWallet()  // 从钱包中提取地址和合约实例
    const [getBalanceLod, steLoading] = useState(false)  // 加载状态
    const [balance, setBalance] = useState(BigInt(0))  // 用户余额

    // 获取余额方法
    const getBalance = useCallback(async () => {
        steLoading(true)  // 设置加载状态为 true
        if (erc20) {
            const balance = await erc20.balanceOf(address)  // 从合约中获取余额
            setBalance(balance)  // 更新余额状态
        }
        steLoading(false)  // 设置加载状态为 false
    }, [address, erc20])

    // 初始化时获取余额
    useEffect(() => {
        getBalance()
    }, [getBalance])

    return { getBalanceLod, balance, getBalance }  // 返回加载状态、余额和获取余额的方法
}

export default useBalance