import { Suspense } from "react";
import { RouterGuard } from "./RouteGuard";
import Layout from "@/Layout";
import Home from '@/pages/home'
import My from '@/pages/my'
import HistoryBill from '@/pages/historyBill'
import IncomeDetail from '@/pages/incomeDetail'
import WithdrawalRecord from '@/pages/withdrawalRecord'
import MyCommunity from '@/pages/myCommunity'
import Invite from '@/pages/invite'
const routeConfig = [
  {
    path: "/",
    element: <Layout />,
    auth: true,
    children: [
      {
        path: "/",
        element: <Home />,
        auth: true,
      },
      {
        path: "/my",
        element: <My />,
        auth: true,
      },
      {
        path: "/historyBill",
        element: <HistoryBill />,
        auth: true,
      },
      {
        path: "/incomeDetail",
        element: <IncomeDetail />,
        auth: true,
      },
      {
        path: "/withdrawalRecord",
        element: <WithdrawalRecord />,
        auth: true,
      },
      {
        path: "/myCommunity",
        element: <MyCommunity />,
        auth: true,
      },
      {
        path: "/invite",
        element: <Invite />,
      },
    ],
  },
];

const AppRouter = () => {
  const element = RouterGuard(routeConfig);
  return <Suspense > {element}</Suspense >;
};
export default AppRouter;
