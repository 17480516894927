import useWallet from "@/store/useWallet"; // 导入管理钱包状态的自定义 Hook
import useListenerTransfer from "./useListenerTransfer"; // 导入监听转账事件的自定义 Hook
import { useState } from "react"; // 导入 useState 钩子，用于状态管理
import { useTranslation } from "react-i18next"; // 导入 i18n 国际化库的 useTranslation 钩子
import { toast } from "react-toastify"; // 导入通知库，用于显示交易状态
import { parseUnits } from "ethers"; // 导入 ethers 库中的 parseUnits 方法，用于将金额转化为指定精度的整数

// 自定义 Hook：用于处理质押操作
// 可接收两个可选回调函数 successFn（成功回调）和 errorFn（失败回调）
const useStake = (successFn?: Function, errorFn?: Function) => {
    // 获取钱包中的 myStaking 合约实例
    const {
        contract: { myStaking }
    } = useWallet();

    // 获取国际化翻译函数
    const { t } = useTranslation();

    // 定义一个状态变量，用于标识质押操作的加载状态
    const [stakeLod, setLoading] = useState(false);

    // 获取监听交易事件的函数，用于追踪交易的成功与否
    const listenerTransferF = useListenerTransfer();

    // 质押函数：接收质押金额（amount）和解锁周期（unlockCycle）
    const stake = async (amount: string, unlockCycle: number) => {
        setLoading(true); // 设置加载状态为 true，开始加载
        // 确保 myStaking 合约实例存在
        if (myStaking) {
            try {
                // 调用质押合约方法，将金额转换为合适的单位，并传入解锁周期
                const { hash } = await myStaking.stake(
                    parseUnits(amount), // 使用 parseUnits 转换金额
                    unlockCycle // 传入解锁周期
                );

                // 通过监听函数查询交易状态
                const result = await listenerTransferF(hash);

                if (result) {
                    // 如果交易成功，显示成功提示，并执行成功回调
                    toast.success(t("hooks.stakedSuccess"));
                    successFn && successFn();
                } else {
                    // 如果交易失败，显示错误提示，并执行失败回调
                    toast.error(t("hooks.stakedFailed"));
                    errorFn && errorFn();
                }
            } catch (e) {
                // 捕获并打印质押过程中的错误
                console.log("useStake", e);
                errorFn && errorFn();
            }
            setLoading(false); // 设置加载状态为 false，停止加载
        }
    };

    // 返回质押函数和加载状态
    return { stake, stakeLod };
};

export default useStake;