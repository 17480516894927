import useWallet from "@/store/useWallet";
import { formatUnits } from "ethers";
import { useCallback, useEffect, useState } from "react";

const useGetUserLp = () => {
    const { contract: { AICDMTokenTest }, wallet: { address } } = useWallet();
    const [userLp, setUserLp] = useState(0)
    const [userLpLoading, setLoading] = useState(false)
    const getUserLp = useCallback(() => {
        if (AICDMTokenTest) {
            try {
                setLoading(true)
                const totalLPs = new Promise<bigint>((resolve, reject) => {
                    AICDMTokenTest.totalLPs().then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error);
                    });
                })
                const userInfo = new Promise<{ lpAmount: bigint, lastAddLPTime: bigint }>((resolve, reject) => {
                    AICDMTokenTest.userInfo(address).then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error);
                    });
                })
                Promise.all([totalLPs, userInfo])
                    .then((results) => {
                        setUserLp(Number(formatUnits(results[1].lpAmount)) / Number(formatUnits(results[0])))
                    }).catch(e => {
                        console.log('e', e);
                    }).finally(() => setLoading(false));
            } catch (e) {
                console.log('useGetUserLp', e);
            }
        }
    }, [AICDMTokenTest, address])
    useEffect(() => {
        getUserLp()
    }, [getUserLp])
    return { userLp, userLpLoading }
}

export default useGetUserLp