import useWallet from "@/store/useWallet";
import { formatUnits } from "ethers";
import { useCallback, useEffect, useState } from "react";

const useGetPrincipalHistoryByLimit = () => {
  // 存放数据
  const [dataPrincipalHistoryByLimit, setDataPrincipalHistoryByLimit] = useState
    <{
      index: BigInt; // 记录的索引
      amount: BigInt; // 提取本金
      profit: BigInt; // 提取的静态利润
      time: BigInt; // 提取时间
    }[]>(
      []
    );
  // loading
  const [principalHistoryByLimitLod, setLoading] = useState(true);
  // 获取合约
  const {
    contract: { myStaking }
  } = useWallet();
  // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
  const [pagesLinkProfits, setPages] = useState("1000000000000000000");
  // 获取
  const {
    wallet: { address },
  } = useWallet();
  // 清空所有数据，然后进行加载首页
  const first = () => {
    setPages("1000000000000000000");
    setDataPrincipalHistoryByLimit([]);
    setLoading(true);
  };
  // 获取
  const setHistoryLinkProfits = () => {
    if (Number(pagesLinkProfits)) setLoading(true);
  };
  // 获取数据的方法
  const getPrincipalHistoryByLimit = useCallback(async () => {
    if (myStaking && Number(pagesLinkProfits) && principalHistoryByLimitLod) {
      try {
        // 获取到数据
        const list = await myStaking.getPrincipalHistoryByLimit(
          address,
          pagesLinkProfits,
          10
        );
        setPages("0");
        // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
        if (list.length) {
          const index = Number(formatUnits(list[list.length - 1].index, 0)) - 1 || 0
          if (index <= 0) {
            setPages("0");
          } else {
            setPages(index.toString());
          }
          // 放入data
          setDataPrincipalHistoryByLimit([...dataPrincipalHistoryByLimit, ...list]);
        } else {
          setPages("0");
        }
      } catch (e) {
        console.log("useGetPrincipalHistoryByLimit", e);
      }
      setLoading(false);
    }
  }, [address, dataPrincipalHistoryByLimit, myStaking, pagesLinkProfits, principalHistoryByLimitLod]);

  useEffect(() => {
    getPrincipalHistoryByLimit();
  }, [getPrincipalHistoryByLimit]);
  return {
    dataPrincipalHistoryByLimit,
    pagesLinkProfits,
    first,
    principalHistoryByLimitLod,
    setHistoryLinkProfits,
  };
};

export default useGetPrincipalHistoryByLimit;
