//  白名单
export const chainIDArr = [56];
export const chainParams: any = {
    56: {
        chainId: 56,
        chainName: "Binance Smart Chain Mainnet", // 自定义链的名称
        nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BSC",
            decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org"],
        blockExplorerUrls: ['https://bscscan.com/'],
    }
};
// 钱包
export const walletList: {
    [key: string]: {
        walletIs: string, // 检查是否有钱包 tp钱包检查是看ethereum.isTokenPocket 而连接仍是ethereum
        ethereum: string, // 初始化的ethereum
        download: string, // 下载链接
    };
} = {
    'MetaMask': {
        walletIs: "ethereum",
        ethereum: 'ethereum',
        download: "https://metamask.io/",

    }
}