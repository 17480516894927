import Copy from "@/components/copy";
import Empty from "@/components/Empty";
import NoDataText from "@/components/NoDataText";
import Return from "@/components/Return";
import { ethereumAddressImage, mobileHidden } from "@/utils";
import useGetDirectAddressList from "@/web3Hooks/useGetDirectAddressList";
import useUserInfo from "@/web3Hooks/useUserInfo";
import { LoadingOutlined } from "@ant-design/icons";
import { formatUnits } from "ethers";
import { useTranslation } from "react-i18next";

const MyCommunity = () => {
    const { t } = useTranslation();
    const { userInfoLoading, userInfo } = useUserInfo()
    const { directAddressListLod, directAddressList } = useGetDirectAddressList()
    return (
        <div className="px-4">
            <Return text={t('community.myCommunity')} />
            <div className="p-4 mt-[10px] box-border bg-[#31363F] rounded mb-4 max-w-[1200px] mx-auto">
                <div className="flex items-center justify-between mb-[14px]">
                    <div className="text-[#B7B9BC] text-xs">{t('community.myStake')}</div>
                    <div className="text-white text-xs font-medium">{userInfoLoading ? <LoadingOutlined />
                        : ~~formatUnits(userInfo.totalDeposit)}</div>
                </div>
                <div className="flex items-center justify-between">
                    <div className="text-[#B7B9BC] text-xs">{t('community.teamSize')}</div>
                    <div className="text-white text-xs font-medium">
                        {userInfoLoading ? <LoadingOutlined />
                            : ~~formatUnits(userInfo.teamNum, 0)}
                    </div>
                </div>
            </div>
            <div className="border rounded-lg border-[#444444] max-w-[1200px] mx-auto">
                <div className="flex items-center justify-center text-xs text-[#A4A4A4] box-border px-3 h-[42px] bg-[#31363F]">
                    <div>{t('community.address')}</div>
                </div>
                <div className="box-border pt-[19px] pb-[25px] h-[calc(100vh-210px)] overflow-y-auto">
                    {directAddressList.map((item, index) => (
                        <div key={index} className="flex items-center justify-between text-xs text-white box-border px-3 py-2 h-[42px]">
                            <img className="w-[14px] mr-1" src={ethereumAddressImage('0x675D6c9eF24109a5524cF6f8a3c27771149C172A')} alt="" />
                            <div className="flex items-center justify-between"> <span className="mr-1">{mobileHidden(item)}</span>
                                <Copy msg={item} className="w-[14px]" /></div>
                        </div>
                    ))}
                    {/* 根据实际数据动态显示加载或无数据提示 */}
                    {directAddressListLod && <div className="text-white mt-3 text-center">{t('loading')} <LoadingOutlined /></div>}
                    {!!!directAddressList.length && !directAddressListLod && <Empty />}
                    {!directAddressListLod && !!directAddressList.length && < NoDataText />}
                </div>
            </div>
        </div>
    );
};

export default MyCommunity;