import useWallet from "@/store/useWallet";
import { formatUnits } from "ethers";
import { useCallback, useEffect, useState } from "react";

const useGetDynamicProfitHistory = () => {
  // 存放数据
  const [dataGetDynamicProfitHistory, setDataGetDynamicProfitHistory] = useState
    <{
      index: BigInt;
      provider: String;  // 提供者地址
      amount: BigInt;    // 分发利润数量
      time: BigInt;      // 分发利润时间
    }[]>(
      []
    );
  // loading
  const [getDynamicProfitHistoryLod, setLoading] = useState(true);
  // 获取合约
  const {
    contract: { myStaking }
  } = useWallet();
  // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
  const [pagesLinkProfits, setPages] = useState("1000000000000000000");
  // 获取
  const {
    wallet: { address },
  } = useWallet();
  // 清空所有数据，然后进行加载首页
  const first = () => {
    setPages("1000000000000000000");
    setDataGetDynamicProfitHistory([]);
    setLoading(true);
  };
  // 获取
  const setHistoryLinkProfits = () => {
    if (Number(pagesLinkProfits)) setLoading(true);
  };
  // 获取数据的方法
  const getGetDynamicProfitHistory = useCallback(async () => {
    if (myStaking && Number(pagesLinkProfits) && getDynamicProfitHistoryLod) {
      try {
        // 获取到数据
        const list = await myStaking.getDynamicProfitHistoryByLimit(
          address,
          pagesLinkProfits,
          10
        );
        setPages("0");
        // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
        if (list.length) {
          const index = Number(formatUnits(list[list.length - 1].index, 0)) - 1 || 0
          if (index <= 0) {
            setPages("0");
          } else {
            setPages(index.toString());
          }
          // 放入data
          setDataGetDynamicProfitHistory([...dataGetDynamicProfitHistory, ...list]);
        } else {
          setPages("0");
        }
      } catch (e) {
        console.log("useGetDynamicProfitHistory", e);
      }
      setLoading(false);
    }
  }, [address, dataGetDynamicProfitHistory, myStaking, pagesLinkProfits, getDynamicProfitHistoryLod]);

  useEffect(() => {
    getGetDynamicProfitHistory();
  }, [getGetDynamicProfitHistory]);
  return {
    dataGetDynamicProfitHistory,
    pagesLinkProfits,
    first,
    getDynamicProfitHistoryLod,
    setHistoryLinkProfits,
  };
};

export default useGetDynamicProfitHistory;
