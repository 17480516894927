import useWallet from "@/store/useWallet"
import { useCallback, useEffect, useState } from "react"

const useIsMaxDeposit = () => {
    const [isMaxDeposit, setIsMaxDeposit] = useState(false)
    const [depositNum, setDepositNum] = useState({
        dayDeposit: BigInt(0), // 每日质押量
        dayMaxDeposit: BigInt(0) // 每天总的最大质押量
    })
    const [isMaxDepositLod, setLoading] = useState(false)
    const { contract: { myStaking } } = useWallet()
    const getIsMaxDeposit = useCallback(() => {
        if (myStaking) {
            // Promise.all 可以在多个异步结束后统一返回参数
            try {
                //  获取每日质押量
                const dayDeposit = new Promise<bigint>((resolve, reject) => {
                    myStaking.getCurDay().then(data => {
                        myStaking.dayDeposit(data).then(data => {
                            resolve(data)
                        }).catch(error => {
                            reject(error);
                        })
                    }).catch(error => {
                        reject(error);
                    });
                })
                // 获取每天总的最大质押量
                const dayMaxDeposit = new Promise<bigint>((resolve, reject) => {
                    myStaking.dayMaxDeposit().then(data => {
                        resolve(data)
                    }).catch(error => {
                        reject(error);
                    })
                })
                setLoading(true)
                Promise.all([dayDeposit, dayMaxDeposit])
                    .then((results) => {
                        setIsMaxDeposit(results[0] >= results[1])
                        setDepositNum({
                            dayDeposit: results[0],
                            dayMaxDeposit: results[1]
                        })
                    }).catch(e => {
                        console.log('e', e);
                    }).finally(() => setLoading(false));
            } catch (e) {
                console.log('useTokenBalance', e);
                setLoading(false)
            }
        }
    }, [myStaking])
    useEffect(() => {
        getIsMaxDeposit()
    }, [getIsMaxDeposit])
    return { isMaxDepositLod, isMaxDeposit, getIsMaxDeposit, depositNum }
}

export default useIsMaxDeposit