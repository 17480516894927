import { useCallback, useRef } from "react";
// 页面触底加载
const useHandleScroll = (loading: boolean, fn: Function, isT: boolean = true, offsetHeight: number = 0) => {
    // 引用滚动容器，用于检测滚动事件
    const scrollableDivRef = useRef(null);
    const handleScroll = useCallback((event: { target: any }) => {
        const scrollableDiv: any = scrollableDivRef.current;
        if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight && !loading) {
            // 如果滚动条已经触底
            if (!loading) {
                fn();
            }
        }
    }, [fn, loading]);
    return { scrollableDivRef, handleScroll }
}


export default useHandleScroll