import useWallet from "@/store/useWallet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useListenerTransfer from "./useListenerTransfer";
import { toast } from "react-toastify";

const useWithdraw = (successFn?: Function, errorFn?: Function) => {
    const {
        contract: { myStaking }, // 从钱包状态中提取合约对象
    } = useWallet();
    const { t } = useTranslation(); // 获取多语言翻译方法

    // 管理注册加载状态
    const [withdrawLod, setLoading] = useState(false);

    // 获取监听事件的函数，用于追踪交易状态
    const listenerTransferF = useListenerTransfer();

    // 注册函数，接收推荐人地址作为参数
    const withdraw = async (index: BigInt) => {
        setLoading(true);
        if (myStaking) {
            // 确保合约对象存在
            try {
                const { hash } = await myStaking.withdraw(index);

                // 使用监听函数查询交易状态
                const result = await listenerTransferF(hash);

                if (result) {
                    // 交易成功时，显示成功通知并调用成功回调
                    toast.success(t("hooks.redeemSuccess"));
                    successFn && successFn();
                } else {
                    // 交易失败时，显示错误通知并调用失败回调
                    toast.error(t("hooks.redeemFailed"));
                    errorFn && errorFn();
                }
            } catch (e) {
                // 捕获并打印注册过程中的错误
                console.log("useWithdraw", e);
            }
            setLoading(false); // 结束注册，设置加载状态为 false
        }
    };

    // 返回注册函数和加载状态
    return { withdraw, withdrawLod };
}

export default useWithdraw