import { useTranslation } from "react-i18next" // 引入 useTranslation 钩子用于国际化
import home from '@/image/home.png' // 引入默认 home 图标
import seleHome from '@/image/seleHome.png' // 引入选中状态的 home 图标
import my from '@/image/my.png' // 引入默认 my 图标
import seleMy from '@/image/seleMy.png' // 引入选中状态的 my 图标
import { useLocation, useNavigate } from "react-router-dom" // 引入路由钩子用于获取路径和导航

// 定义底部导航栏组件
const Foot = () => {
  const { t } = useTranslation() // 使用国际化函数 t 进行文案翻译
  const navigate = useNavigate() // 使用 navigate 进行页面跳转
  const { pathname } = useLocation() // 获取当前路径以控制选中状态

  // 定义底部导航栏的数据
  const tabData = [
    {
      img: home, // 未选中状态的图标
      seleImg: seleHome, // 选中状态的图标
      name: t('foot.home'), // 导航名称，通过国际化获取
      path: '/' // 路径
    },
    {
      img: my, // 未选中状态的图标
      seleImg: seleMy, // 选中状态的图标
      name: t('foot.my'), // 导航名称，通过国际化获取
      path: '/my' // 路径
    }
  ]

  // 返回底部导航栏的 JSX
  return (
    <div className="flex items-center justify-between box-border bg-[#31363F] px-[65.5px] pt-[21px] pb-[30px] w-full h-[96px] fixed bottom-0 left-0 md:hidden">
      {tabData.map((item, index) => {
        return (
          <div
            key={index}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => { navigate(item.path) }} // 点击导航项后跳转到对应路径
          >
            <img
              className="w-[30px]"
              src={pathname === item.path ? item.seleImg : item.img} // 根据路径切换图标显示
              alt=""
            />
            <p
              className={`text-xs ${pathname === item.path ? 'text-[#FFB040]' : 'text-[#A7A9AC]'}`} // 根据路径切换文字颜色
            >
              {item.name} {/* 显示导航名称 */}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default Foot // 导出 Foot 组件