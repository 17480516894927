import useWallet from "@/store/useWallet"; // 导入管理钱包状态的 Hook
import useListenerTransfer from "./useListenerTransfer"; // 导入监听转账事件的 Hook
import { useState } from "react"; // 导入 useState 钩子
import { useTranslation } from "react-i18next"; // 导入国际化钩子
import { toast } from "react-toastify"; // 导入通知库

// 定义 useRegister 自定义 Hook，用于用户注册操作
// 接收可选的成功回调 (successFn) 和失败回调 (errorFn)
const useRegister = (successFn?: Function, errorFn?: Function) => {
  const {
    contract: { myStaking }, // 从钱包状态中提取合约对象
  } = useWallet();
  const { t } = useTranslation(); // 获取多语言翻译方法

  // 管理注册加载状态
  const [registerLod, setLoading] = useState(false);

  // 获取监听事件的函数，用于追踪交易状态
  const listenerTransferF = useListenerTransfer();

  // 注册函数，接收推荐人地址作为参数
  const register = async (referral: string) => {
    setLoading(true); // 开始注册，设置加载状态为 true

    if (myStaking) {
      // 确保合约对象存在
      try {
        // 调用合约的 register 方法，传入推荐人地址，发起注册交易
        const { hash } = await myStaking.register(referral);

        // 使用监听函数查询交易状态
        const result = await listenerTransferF(hash);

        if (result) {
          // 交易成功时，显示成功通知并调用成功回调
          toast.success(t("hooks.registerSuccess"));
          successFn && successFn();
        } else {
          // 交易失败时，显示错误通知并调用失败回调
          toast.error(t("hooks.registerError"));
          errorFn && errorFn();
        }
      } catch (e) {
        // 捕获并打印注册过程中的错误
        console.log("useRegister", e);
      }
      setLoading(false); // 结束注册，设置加载状态为 false
    }
  };

  // 返回注册函数和加载状态
  return { register, registerLod };
};

export default useRegister; // 导出 useRegister Hook
