import './style/index.css'
import AppRouter from '@/router'
import { ConfigProvider, ThemeConfig } from 'antd';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

// import "@/log/index";
function App() {
  const config: ThemeConfig = {
    token: {
    },
    components: {
      Button: {
        defaultBg: '#FFB040', // 默认按钮背景色
        defaultBorderColor: "#FFB040", // 默认按钮边框颜色	
        defaultColor: "#242930", // 默认按钮文本颜色
        defaultHoverBg: "#FFB040", // 默认按钮悬浮态背景色
        defaultHoverBorderColor: "#FFB040", // 默认按钮悬浮态边框颜色
        defaultActiveBg: "#FFB040", // 默认按钮激活态背景色
        defaultActiveBorderColor: "#FFB040", // 默认按钮激活态边框颜色
        defaultActiveColor: "#242930", // 默认按钮激活态文字颜色
        defaultHoverColor: "#242930",// 默认按钮悬浮态文本颜色
        colorPrimary: "##FFB040",  // 品牌色
        algorithm: true, // 启用算法
      },
      Modal: {
        contentBg: "#31363F", // 内容区域背景色
        headerBg: "#31363F", // 顶部背景色
        titleColor: "#FFFFFF", // 标题字体颜色
        colorIcon: "#FFFFFF",//  控制弱操作图标的颜色，例如 allowClear 或 Alert 关闭按钮
      },
      InputNumber: {
        colorBgContainer: "#31363F", // 组件的容器背景色，例如：默认按钮、输入框等
        controlHeight: 56, // Ant Design 中按钮和输入框等基础控件的高度
        activeBorderColor: "", // 激活态边框色
        hoverBorderColor: "", // 悬浮态边框色
        colorBorder: "",  // 默认使用的边框颜色
        activeShadow: "", // 激活态阴影,
        lineWidth: 0,
        colorText: '#fff',
        colorTextPlaceholder: "#AFAFAF"
      },
      Tabs: {
        cardBg: "rgba(0, 0, 0, 0)",
        cardGutter: 9,
        horizontalItemPadding: "24px 0 12px 0",
        itemSelectedColor: "#FFFFFF", // 标签选中态文本颜色
        itemActiveColor: "#0A0A0A", // 标签激活态文本颜色
        itemHoverColor: "", // 标签悬浮态文本颜色
        itemColor: "#ADADAD", // 标签文本颜色
        inkBarColor: "#FFFFFF", // 指示条颜色
        colorBorderSecondary: "transparency", // 比默认使用的边框色要浅一级，此颜色和 colorSplit 的颜色一致。使用的是实色。
      },
      Input: {
        colorBgContainer: "#31363F", // 组件的容器背景色，例如：默认按钮、输入框等
        controlHeight: 48, // Ant Design 中按钮和输入框等基础控件的高度
        activeBorderColor: "#31363F", // 激活态边框色
        hoverBorderColor: "#31363F", // 悬浮态边框色
        colorBorder: "#31363F",  // 默认使用的边框颜色
        activeShadow: "", // 激活态阴影,
        colorText: '#fff',
        algorithm: true, // 启用算法
        colorTextPlaceholder: ""
      },
    }
  };
  return (
    <div className="MyApp">
      <ConfigProvider
        theme={config}
      >
        <ToastContainer stacked theme="dark" />
        <AppRouter />
      </ConfigProvider>
    </div>
  );
}

export default App;
