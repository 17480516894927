import useWallet from "@/store/useWallet";
import { useState } from "react";


const useStakes = () => {
    const {
        wallet: { address },
        contract: { myStaking }, // 从钱包状态中提取合约对象
    } = useWallet();

    // 管理注册加载状态
    const [stakesLod, setLoading] = useState(false);

    // 注册函数，接收推荐人地址作为参数
    const stakes = async (index: BigInt) => {
        setLoading(true); // 开始注册，设置加载状态为 true
        if (myStaking) {
            // 确保合约对象存在
            try {
                // 调用合约的 register 方法，传入推荐人地址，发起注册交易
                const data = await myStaking.stakes(address, index);
                setLoading(false);
                return data
            } catch (e) {
                // 捕获并打印注册过程中的错误
                console.log("useStakes", e);
            }
            setLoading(false);
        }
    };

    // 返回注册函数和加载状态
    return { stakes, stakesLod };
}

export default useStakes
