import banner from '@/image/banner.png'
import { truncateDecimals } from '@/utils'
import useBalance from '@/web3Hooks/useBalance'
import useAllowance from '@/web3Hooks/useGetAllowance'
import useIsMaxDeposit from '@/web3Hooks/useIsMaxDeposit'
import useStake from '@/web3Hooks/useStake'
import useUserInfo from '@/web3Hooks/useUserInfo'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, InputNumber, InputNumberProps } from 'antd'
import { formatUnits, parseUnits } from 'ethers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
const dayData = [
  { day: 5, coefficient: 0.4 },
  { day: 10, coefficient: 0.6 },
  { day: 20, coefficient: 0.8 },
  { day: 30, coefficient: 1 }
]
const Home = () => {
  const [number, setNumber] = useState('')
  const [day, setDay] = useState(0)
  const [isStop, setIsStop] = useState(false)
  const { getBalanceLod, balance, getBalance } = useBalance(); // 获取余额相关函数和状态
  const { t } = useTranslation()
  const { getHasReferrerUserInfo, userInfo } = useUserInfo()
  const { isMaxDeposit, getIsMaxDeposit, depositNum } = useIsMaxDeposit()
  const { stake, stakeLod } = useStake(() => {
    getBalance();
    getAllowance();
    getIsMaxDeposit();
    getHasReferrerUserInfo();
    setNumber('')
  }, () => {
    getBalance();
    getAllowance();
    getIsMaxDeposit();
    getHasReferrerUserInfo();
  })
  const { allowance, allowanceLod, approve, getAllowance } = useAllowance()

  const onChange: InputNumberProps['onChange'] = (value) => {
    setNumber(value as string || '')
  };
  const pledgeFun = () => {
    // 用户授权额度足够吗 true 🐶 false 不🐶
    const isAllowance = parseUnits(number) > allowance
    isAllowance ? approve() : stake(number, dayData[day - 1].day)
  }
  useEffect(() => {
    const is = (Number(number) < 100 || isMaxDeposit || Number(number) > 1000 || Number(number) % 10 !== 0 || Number(formatUnits(userInfo.totalDeposit)) + Number(number) > 5000)
    setIsStop(is)
  }, [isMaxDeposit, number, userInfo.totalDeposit])
  return (
    <div className="pt-6 h-[calc(100vh-162px)] overflow-y-auto relative mx-auto max-w-[1200px]" >
      <img className='w-full mb-10' src={banner} alt="" />
      <div className='p-4 '>
        <div className='flex justify-between mb-2 text-[14px]'>
          <span className='text-[#A7A9AC]'>{t('home.amount')}</span>
          <span className='text-white'>{t('home.balance')}:{getBalanceLod ? <LoadingOutlined />
            : truncateDecimals(formatUnits(balance), 4)}</span>
        </div>
        <InputNumber value={number || ''} addonAfter={<div onClick={() => { setNumber(formatUnits(balance)) }}>MAX</div>} stringMode={true} className='w-full rounded-md bg-[#31363F]' placeholder={t('home.placeholder')} controls={false} onChange={onChange} />
        <p className='mt-2 text-xs flex items-center text-red-500'>
          {isStop && number && `${t('home.stakeLimit')}${~~formatUnits(depositNum.dayMaxDeposit)}U ${t('home.stakeLimit1')} ${~~formatUnits(depositNum.dayDeposit)}U`}
        </p>
        <p className='mt-6 text-[#A7A9AC] text-[14px] mb-2'>{t('home.daysOfPledge')}</p>
        <div className='flex items-center justify-between mb-6'>
          {dayData.map((item, index) => {
            return <div key={index} onClick={() => { setDay(index + 1) }} className={`${day === index + 1 ? 'border-[#FFB040]' : 'border-[#49505A]'} cursor-pointer flex border-[2px] rounded items-center justify-center w-[75px] h-[64px]`}>
              <span className='text-white text-[16px] mr-[2px]'>{item.day}</span>
              <span className='text-[#A7A9AC] text-xs'>{t('home.day')}</span>
            </div>
          })}
        </div>
        {
          day > 0 && <div className='px-4 py-3 bg-[#333A43] flex items-center justify-between'>
            <span className='text-[14px] text-[#A7A9AC]'>{t('home.coefficientOfReturn')}</span>
            <span className='text-[16px] text-[#E14C4C]'>{dayData[day - 1].coefficient}%</span>
          </div>
        }
        <Button loading={stakeLod || allowanceLod}
          onClick={() => {
            number && day
              && !isStop && pledgeFun()
          }}
          className={`w-full h-[48px] mt-20 ${number && day &&
            !isStop ? '' : 'opacity-60'} `}>
          {parseUnits(number || 0 + '') > allowance ? t('home.authorize') : t('home.confirm')}
        </Button>
      </div>
    </div>
  )
}

export default Home