import Copy from "@/components/copy"; // 导入 Copy 组件，用于复制文本功能
import copyT from '@/image/copyT.png'; // 导入复制图标
import historyBill from '@/image/historyBill.png'; // 导入历史账单图标
import incomeDetail from '@/image/incomeDetail.png'; // 导入收入明细图标
import withdrawalRecord from '@/image/withdrawalRecord.png'; // 导入提现记录图标
import myCommunity from '@/image/myCommunity.png'; // 导入我的社区图标
import right from '@/image/right.png'; // 导入右箭头图标
import { mobileHidden, truncateDecimals } from "@/utils"; // 导入辅助函数，用于隐藏手机号码或地址部分字符
import { useTranslation } from "react-i18next"; // 导入国际化钩子
import { useNavigate } from "react-router-dom"; // 导入用于导航的钩子
import useUserInfo from "@/web3Hooks/useUserInfo"; // 导入获取用户信息的自定义 Hook
import useWallet from "@/store/useWallet"; // 导入钱包状态的自定义 Hook
import { LoadingOutlined } from "@ant-design/icons"; // 导入加载图标
import useGetUserLp from "@/web3Hooks/useGetUserLp";

// `My` 组件：显示用户信息、历史记录、收入详情等
const My = () => {
  const { userInfoLoading, userInfo } = useUserInfo(); // 获取用户信息及加载状态
  const { wallet: { address } } = useWallet(); // 获取钱包地址
  const { t } = useTranslation(); // 获取翻译函数
  const navigate = useNavigate(); // 获取导航函数
  const { userLp, userLpLoading } = useGetUserLp()
  // 定义各类记录的数据，包括显示文本、路径和图标
  const tabData = [
    { text: t('my.historyBill'), path: '/historyBill', src: historyBill },
    { text: t('my.incomeDetail'), path: '/incomeDetail', src: incomeDetail },
    { text: t('my.withdrawalRecord'), path: '/withdrawalRecord', src: withdrawalRecord },
    { text: t('my.myCommunity'), path: '/myCommunity', src: myCommunity },
  ];

  return (
    <div className="pt-6 px-4 max-w-[1200px] mx-auto">
      {/* 顶部用户信息显示区域 */}
      <div className="rounded-[10px] bg-[#31363F] px-[15px] text-[14px] text-white mb-4">
        {/* 用户邀请地址 */}
        <div className="flex h-[51.5px] items-center justify-between border-b border-[#474c57]">
          {t('my.invitationAddress')}：{mobileHidden(address, 6, 5)} {/* 隐藏部分地址 */}
          <Copy msg={address} src={copyT} className="w-5" /> {/* 复制邀请地址按钮 */}
        </div>

        {/* 参考地址 */}
        <div className="flex h-[51.5px] items-center justify-between">
          {t('my.addressOfTheReference')}：
          {userInfoLoading
            ? <LoadingOutlined /> // 如果用户信息加载中，显示加载图标
            : <>
              {mobileHidden(userInfo.referrer, 6, 5)} {/* 隐藏部分参考地址 */}
              <Copy msg={userInfo.referrer} src={copyT} className="w-5" /> {/* 复制参考地址按钮 */}
            </>}
        </div>
      </div>
      <div
        className={`flex items-center justify-between h-[50px] rounded-[10px] bg-[#31363F] px-[15px] text-[14px] text-white mb-4`}
      >
        <p>LP</p>
        <span className="text-base">{userLpLoading ? <LoadingOutlined />
          : truncateDecimals((userLp * 100 + ''), 2)}%</span>
      </div>
      {/* 中间记录展示区域 */}
      <div className="rounded-[10px] bg-[#31363F] px-[15px] text-[14px] text-white">
        {
          // 显示每个记录项
          tabData.map((item, index) => {
            return (
              <div
                className={`flex items-center justify-between h-[50px] cursor-pointer ${index === tabData.length - 1 ? '' : 'border-b border-[#474c57]'}`}
                key={index}
                onClick={() => { item.path && navigate(item.path) }} // 点击导航到相应路径
              >
                <div className="flex items-center">
                  <img className="w-[30px] mr-1" src={item.src} alt="" /> {/* 图标 */}
                  <span className="text-base">{item.text}</span> {/* 显示的文本 */}
                </div>
                <img className="w-[30px]" src={right} alt="" /> {/* 右箭头图标 */}
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default My;