import useAddressConvert from "@/hooks/useAddressConvert"; // 导入地址转换 Hook
import useRegister from "@/web3Hooks/useRegister"; // 导入注册相关的 Web3 Hook
import { Button, Input } from "antd"; // 从 Ant Design 导入按钮和输入框组件
import { useState } from "react"; // 导入 useState 钩子
import { useTranslation } from "react-i18next"; // 导入国际化钩子
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // 导入通知库

// 定义 Invite 组件，处理邀请操作
const Invite = () => {
    const navigate = useNavigate() // 注册路由
    const { t } = useTranslation(); // 获取多语言翻译方法
    const [referrer, setReferrer] = useState(''); // 定义推荐人地址状态
    const { validateAddress0x } = useAddressConvert(); // 使用地址格式校验方法
    const { register, registerLod } = useRegister(() => { navigate('/') }); // 使用注册方法和注册加载状态
    // 处理输入框内容变化
    const onChange = (e: any) => {
        setReferrer(e.target.value); // 更新推荐人地址
    };

    // 注册推荐人地址的函数
    const registerF = () => {
        // 校验推荐人地址格式是否正确
        const flag = validateAddress0x(referrer);
        if (!flag) {
            // 如果格式不正确，显示警告提示
            return toast.warning(t('invite.invalidAddressFormat'), { autoClose: 1000 });
        }
        // 地址格式正确，调用注册方法
        register(referrer);
    };

    return (
        <div className="pt-6 px-4 ">
            {/* 推荐人地址标签 */}
            <p className="mb-2 text-[14px] text-[#A7A9AC]">{t('invite.addressLabel')}</p>

            {/* 推荐人地址输入框 */}
            <Input
                value={referrer}
                onChange={onChange}
                placeholder={t('invite.placeholder')} // 多语言占位符
                className="mb-4"
            />

            {/* 注册按钮 */}
            <Button
                loading={registerLod} // 加载状态
                onClick={() => { referrer && registerF() }} // 点击时调用注册函数
                className={`w-full h-[48px] ${referrer ? '' : 'opacity-60'}`} // 根据 referrer 变更按钮透明度
            >
                {t('invite.confirm')} {/* 多语言确认文本 */}
            </Button>
        </div>
    );
};

export default Invite; // 导出 Invite 组件