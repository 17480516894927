import { useCallback, useEffect, useState } from "react"
import { MaxUint256 } from "ethers" // 引入ethers库中的最大整数常量，用于无限授权额度
import useListenerTransfer from "./useListenerTransfer" // 引入自定义钩子，用于监听交易成功与否
import { useTranslation } from "react-i18next" // 引入i18n库的useTranslation钩子，用于国际化
import useWallet from "@/store/useWallet" // 引入自定义钱包状态管理钩子
import { toast } from "react-toastify" // 引入toast库，用于弹出提示消息
import contractAll from "@/abi" // 引入合约的ABI配置文件

// 自定义钩子：用于获取和管理ERC20代币的授权额度
const useAllowance = () => {
    // 获取国际化翻译函数
    const { t } = useTranslation()

    // 从useWallet钩子中获取钱包地址、链ID和合约实例
    const { wallet: { address, chainId }, contract: { erc20 } } = useWallet()

    // 定义一个状态变量，用于存储用户的授权额度
    const [allowance, setAllowance] = useState<bigint>(BigInt(0))

    // 获取监听交易事件的函数
    const listenerTransferF = useListenerTransfer()

    // 定义一个状态变量，用于标识加载状态
    const [allowanceLod, setLoading] = useState(false)

    // 获取授权额度的函数
    const getAllowance = useCallback(async () => {
        // 确保钱包地址和ERC20合约实例存在
        if (address && erc20) {
            setLoading(true) // 启动加载状态
            try {
                // 查询授权额度
                const allowances = await erc20.allowance(address, contractAll[chainId].myStaking.address)
                setAllowance(allowances) // 更新授权额度状态
            } catch (e) {
                setAllowance(BigInt(0)) // 出现错误时将授权额度设为0
                console.log('useAllowance', e)
            }
            setLoading(false) // 停止加载状态
        }
    }, [address, chainId, erc20])

    // 执行授权的函数
    const approve = async () => {
        // 确保ERC20合约实例存在
        if (erc20) {
            setLoading(true) // 启动加载状态
            try {
                // 执行授权，授权额度为最大整数，表示无限授权
                const { hash } = await erc20.approve(contractAll[chainId].myStaking.address, MaxUint256)

                // 使用交易哈希监听交易结果
                const relset = await listenerTransferF(hash)
                if (relset) {
                    // 如果交易成功，显示成功提示
                    toast.success(t("hooks.successfulAuthorization"))

                    // 重新查询授权额度
                    getAllowance()
                } else {
                    // 如果交易失败，显示失败提示
                    toast.error(t("hooks.authorizationFailed"))
                }
            } catch (e) {
                console.log('useAllowance', e) // 打印错误信息
                setLoading(false) // 停止加载状态
            }
        }
    }

    // 初始化时或依赖变更时调用getAllowance查询授权额度
    useEffect(() => {
        getAllowance()
    }, [getAllowance])

    // 返回当前授权额度、加载状态、授权函数和查询额度函数
    return { allowance, allowanceLod, approve, getAllowance }
}

export default useAllowance