import Empty from "@/components/Empty";
import NoDataText from "@/components/NoDataText";
import Return from "@/components/Return";
import useHandleScroll from "@/hooks/useHandleScroll";
import { formatTimeToStr } from "@/utils";
import useGetDynamicProfitHistoryByLimit from "@/web3Hooks/useGetDynamicProfitHistoryByLimit";
import useGetPrincipalHistoryByLimit from "@/web3Hooks/useGetPrincipalHistoryByLimit";
import { LoadingOutlined } from "@ant-design/icons";
import { Tabs, TabsProps } from "antd";
import { formatUnits } from "ethers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const IncomeDetail = () => {
    const { t } = useTranslation();
    const [pageNum, setPageNum] = useState('1');
    const onChange = (key: string) => {
        setPageNum(key);
    };
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('incomeDetail.staticEarnings'),
        },
        {
            key: '2',
            label: t('incomeDetail.dynamicEarnings'),
        },
    ];
    return (
        <div className="px-4">
            <Return text={t('incomeDetail.incomeDetail')} />
            <Tabs defaultActiveKey={pageNum} centered items={items} onChange={onChange} />
            <div className=" max-w-[1200px] mx-auto">
                {pageNum === '1' && <Page1 />}
                {pageNum === '2' && <Page2 />}
            </div>
        </div>
    );
};

export default IncomeDetail;

const Page1 = () => {
    const { dataPrincipalHistoryByLimit,
        pagesLinkProfits,
        // first,
        principalHistoryByLimitLod,
        setHistoryLinkProfits
    } = useGetPrincipalHistoryByLimit()

    const { scrollableDivRef, handleScroll } = useHandleScroll(principalHistoryByLimitLod, setHistoryLinkProfits)

    const { t } = useTranslation();
    return (
        <div ref={scrollableDivRef} onScroll={handleScroll} className="w-full h-[calc(100vh-150px)] overflow-y-auto box-border">
            {dataPrincipalHistoryByLimit.map((item, index) => (
                <Item key={index} title={t('incomeDetail.staticEarnings')} time={item.time} profit={item.profit} />
            ))}
            <div className="flex justify-center pt-2">
                {/* 根据实际数据动态显示加载或无数据提示 */}
                {principalHistoryByLimitLod && <div className="text-white mt-3 text-center">{t('loading')} <LoadingOutlined /></div>}
                {!dataPrincipalHistoryByLimit.length && !principalHistoryByLimitLod && !Number(pagesLinkProfits) && <Empty />}
                {!Number(pagesLinkProfits) && !!dataPrincipalHistoryByLimit.length && (
                    <NoDataText />
                )}
            </div>
        </div>

    );
};

const Page2 = () => {
    const { t } = useTranslation();
    const { dataGetDynamicProfitHistory,
        pagesLinkProfits,
        // first,
        getDynamicProfitHistoryLod,
        setHistoryLinkProfits,
    } = useGetDynamicProfitHistoryByLimit()
    const { scrollableDivRef, handleScroll } = useHandleScroll(getDynamicProfitHistoryLod, setHistoryLinkProfits)

    return (
        <div ref={scrollableDivRef} onScroll={handleScroll} className="w-full h-[calc(100vh-150px)] overflow-y-auto box-border">

            {dataGetDynamicProfitHistory.map((item, index) => (
                <Item key={index} title={t('incomeDetail.dynamicEarnings')} time={item.time} profit={item.amount} />
            ))}

            {getDynamicProfitHistoryLod && <div className="text-white mt-3 text-center">{t('loading')} <LoadingOutlined /></div>}
            {!dataGetDynamicProfitHistory.length && !getDynamicProfitHistoryLod && !Number(pagesLinkProfits) && <Empty />}
            {!Number(pagesLinkProfits) && !!dataGetDynamicProfitHistory.length && (
                <NoDataText />
            )}
        </div>
    );
};

const Item = ({ title, time, profit }: { title: string, time: BigInt, profit: BigInt }) => {
    return (
        <div className="flex items-center justify-between py-[10px] border-b border-[#31363F]">
            <div>
                <p className="text-sm text-white mb-[6px]">{title}</p>
                <p className="text-xs text-[#898B9D]">{formatTimeToStr(Number(formatUnits(time + '', 0)) * 1000)}</p>
            </div>
            <div className="text-sm font-medium text-[#46BE43]">+{Number(formatUnits(profit + ''))}</div>
        </div>
    );
};