import { Interface, InterfaceAbi } from "ethers";
const erc20Abi = require("./json/erc20.json");
const MyStaking = require("./json/MyStaking.json");
console.log("环境", process.env.REACT_APP_ENV);
const AICDMTokenTest = require("./json/AICDMTokenTest.json");
const contractAll: {
  [key: number]: {
    [key: string]: { address: string; abi: Interface | InterfaceAbi };
  };
} = {
  56: {
    erc20: { abi: erc20Abi, address: "" },
    myStaking: { abi: MyStaking, address: "" },
    AICDMTokenTest: { abi: AICDMTokenTest, address: "" },
  },
};
if (process.env.REACT_APP_ENV === "development") {
  contractAll[56].erc20.address = "0xe66FD8557AF7bCB05b58dDb73321d5473BBfcC1b";
  contractAll[56].myStaking.address =
    "0xeA78D1bbeE1eae112836e555107aE6Dc38f3D7f2";
  contractAll[56].AICDMTokenTest.address =
    "0xF4111BA14a9FF6038c0e210BF48f2D15AC6fC171";
  //开发环境
} else if (process.env.REACT_APP_ENV === "test") {
  // 测试环境
  contractAll[56].erc20.address = "0xe66FD8557AF7bCB05b58dDb73321d5473BBfcC1b";
  contractAll[56].myStaking.address =
    "0xeA78D1bbeE1eae112836e555107aE6Dc38f3D7f2";
  contractAll[56].AICDMTokenTest.address =
    "0xF4111BA14a9FF6038c0e210BF48f2D15AC6fC171";
} else {
  //生产环境
  contractAll[56].erc20.address = "0x55d398326f99059fF775485246999027B3197955";
  contractAll[56].myStaking.address =
    "0x88c26ba06ef982c495bdFB72a2c079738252054E";
  contractAll[56].AICDMTokenTest.address =
    "0xd6886ba42da2793E6b1309F696c19b9DBf50D828";
}

export default contractAll;
