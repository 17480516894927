import useWallet from "@/store/useWallet"
import { useCallback, useEffect, useState } from "react"

const useGetDirectAddressList = () => {
    const [directAddressListLod, setLoading] = useState(false) // 加载状态
    const [directAddressList, setDirectAddressList] = useState<string[]>([])

    // 获取钱包地址和合约实例
    const { wallet: { address }, contract: { myStaking } } = useWallet()

    const getDirectAddressList = useCallback(async () => {
        setLoading(true)  // 设置加载状态为 true，表示开始加载
        try {
            if (myStaking) { // 检查合约是否存在
                // 通过合约调用获取用户信息
                const addressArr = await myStaking.getDirectAddressList(address)
                // 更新用户信息状态
                setDirectAddressList(addressArr)
            }
        } catch (e) {
            console.log('useGetDirectAddressList', e)  // 捕获异常并打印错误信息
        }
        setLoading(false)  // 设置加载状态为 false，表示加载完成
    }, [myStaking, address])

    // 在组件挂载或依赖更新时调用获取推荐人信息的方法
    useEffect(() => {
        getDirectAddressList()
    }, [getDirectAddressList])

    // 返回推荐人信息及加载状态
    return { getDirectAddressList, directAddressListLod, directAddressList }
}

export default useGetDirectAddressList