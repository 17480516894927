import Empty from "@/components/Empty"
import NoDataText from "@/components/NoDataText"
import Return from "@/components/Return"
import useHandleScroll from "@/hooks/useHandleScroll"
import { formatTimeToStr } from "@/utils"
import useGetStakeByLimit, { HistoryBillProps } from "@/web3Hooks/useGetStakeByLimit"
import useStakes from "@/web3Hooks/useStakes"
import useWithdraw from "@/web3Hooks/useWithdraw"
import { LoadingOutlined } from "@ant-design/icons"
import { Button, Modal } from "antd"
import dayjs from "dayjs"
import { formatUnits } from "ethers"
import { useState } from "react"
import { useTranslation } from "react-i18next"
const HistoryBill = () => {
    const [itemInfo, setItemInfo] = useState<HistoryBillProps |
        null>(null)
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false); // 控制弹窗的状态
    const { dataStakeByLimit,
        pagesLinkProfits,
        stakeByLimitLod,
        setHistoryLinkProfits,
        setDataStakeByLimit
    } = useGetStakeByLimit()

    const { stakes, stakesLod } = useStakes()
    const setData = async () => {
        const dataArr: HistoryBillProps[] = []
        if (itemInfo != null) {
            const data = await stakes(itemInfo.id)
            dataStakeByLimit.forEach((item) => {
                data.id === item.id ? dataArr.push(data)
                    : dataArr.push(item)
            })
            setDataStakeByLimit(dataArr as any)
            setIsModalOpen(false);
        }
    }
    const { withdraw, withdrawLod } = useWithdraw(setData)
    const { scrollableDivRef, handleScroll } = useHandleScroll(stakeByLimitLod, setHistoryLinkProfits)

    const showModal = (item: HistoryBillProps) => {
        return () => {
            setItemInfo(item)
            setIsModalOpen(true)
        }
    };
    const handleCancel = () => {
        if (itemInfo != null) {
            withdraw(itemInfo.id)
        }
    };
    return (
        <div className="px-4 ">
            <Return text={t('historyBill.historyBill')} />
            <div ref={scrollableDivRef} onScroll={handleScroll} className='scroll mt-[10px] h-[calc(100vh-80px)] overflow-y-auto max-w-[1200px] mx-auto '>
                {dataStakeByLimit.map((item, index) => {
                    return <div key={index} className='pt-[49px] px-[15px] pb-[17px] box-border bg-[#31363F] rounded-xl relative mt-[21px]'>
                        <div className="absolute left-0 top-[10px] text-xs text-[#ADADAD] px-[21px] py-[2px] bg-[#464A53]  rounded-r-[10px]">
                            ID：{Number(formatUnits(item.id, 0)) + 1}
                        </div>
                        <div className="flex items-start justify-between mb-[13px]">
                            <div className="text-[#828590] text-xs">{t('historyBill.stakeAmount')}</div>
                            <div className="text-white text-sm font-medium">{Number(formatUnits(item.amount + ''))} USDT</div>
                        </div>
                        {!item.isWithdraw && <div className="flex items-start justify-between  mb-[13px]">
                            <div className="text-[#828590] text-xs">{t('historyBill.stakeStatus')}</div>
                            <div className="text-white text-sm font-medium">{t('historyBill.underPledge')}</div>
                        </div>}
                        <div className="flex items-start justify-between  mb-[13px]">
                            <div className="text-[#828590] text-xs">{t('historyBill.lockTime')}</div>
                            <div className="text-white text-sm font-medium">
                                <p className="mb-1.5">{t('historyBill.startTime')}：{formatTimeToStr(Number(formatUnits(item.startTime + '', 0)) * 1000)}</p>
                                <p>{t('historyBill.endTime')}：{formatTimeToStr(Number(formatUnits(item.endTime + '', 0)) * 1000)}</p>
                            </div>
                        </div>
                        <div className="flex items-start justify-between mb-[18px]">
                            <div className="text-[#828590] text-xs">{t('historyBill.earnings')}</div>
                            <div className="text-white text-sm font-medium">{Number(formatUnits(item.reward + ''))} USDT</div>
                        </div>
                        {dayjs().unix() > Number(formatUnits(item.endTime + '', 0)) && !item.isWithdraw && <Button onClick={showModal(item)} block className="h-[42px] text-[#FFB040] bg-[#3E4452] border-[#3E4452] font-medium">{t('historyBill.redeem')}</Button>}
                        {dayjs().unix() < Number(formatUnits(item.endTime + '', 0)) && <Button block className="h-[42px] text-[#8b847a] bg-[#3E4452] border-[#3E4452] font-medium">{t('historyBill.redeem')}</Button>}
                        {item.isWithdraw && <Button block className="h-[42px] text-[#9BA2B3] bg-[#3E4452] border-[#3E4452] font-medium">{t('historyBill.redeemed')}</Button>}
                    </div>
                })}
                {/* 根据实际数据动态显示加载或无数据提示 */}
                {stakeByLimitLod && <div className="text-white mt-3 text-center">{t('loading')} <LoadingOutlined /></div>}
                {!dataStakeByLimit.length && !stakeByLimitLod && !Number(pagesLinkProfits) && <Empty />}
                {!Number(pagesLinkProfits) && !!dataStakeByLimit.length && (
                    <NoDataText />
                )}
            </div>

            <Modal
                footer={null} // 不显示底部按钮
                open={isModalOpen} // 弹窗是否打开
                closable={false}
                onOk={() => {
                    setIsModalOpen(false);
                }} // 确认关闭
                onCancel={() => {
                    setIsModalOpen(false);
                }} // 取消关闭
                centered={true}
            >
                <div className="py-[6px]">
                    {itemInfo && <p className="text-white text-sm">
                        {t('historyBill.confirmRedemption1')}{Number(formatUnits(itemInfo.amount + ''))}USDT{t('historyBill.confirmRedemption2')}
                    </p>}
                    <div className="flex items-center justify-between mt-9">
                        <Button loading={withdrawLod || stakesLod} onClick={handleCancel} block className="h-[42px] rounded text-[#3E4452] bg-[#FFB040] mr-[23px] font-medium">{t('historyBill.confirm')}</Button>
                        <Button onClick={() => {
                            setIsModalOpen(false);
                        }} block className="h-[42px] rounded text-[#FFB040] border-[2px] bg-[#3E4452]  font-medium">{t('historyBill.cancel')}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default HistoryBill