import useWallet from "@/store/useWallet";
import { formatUnits } from "ethers";
import { useCallback, useEffect, useState } from "react";
export type HistoryBillProps = {
  id: bigint;           // 订单ID(从 0 开始)
  amount: bigint;       // 本金
  reward: bigint;       // 订单到期后能提取的收益
  startTime: bigint;    // 订单开始时间
  endTime: bigint;      // 订单结束时间
  rate: bigint;         // 利率
  unlockCycleDay: bigint;  // 解锁周期(用天表示)
  isWithdraw: boolean;      // 是否已经提取}
}

const useGetStakeByLimit = () => {
  // 存放数据
  const [dataStakeByLimit, setDataStakeByLimit] = useState
    <HistoryBillProps[]>(
      []
    );
  // loading
  const [stakeByLimitLod, setLoading] = useState(true);
  // 获取合约
  const {
    contract: { myStaking }
  } = useWallet();
  // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
  const [pagesLinkProfits, setPages] = useState("1000000000000000000");
  // 获取
  const {
    wallet: { address },
  } = useWallet();
  // 清空所有数据，然后进行加载首页
  const first = () => {
    setPages("1000000000000000000");
    setDataStakeByLimit([]);
    setLoading(true);
  };
  // 获取
  const setHistoryLinkProfits = () => {
    if (Number(pagesLinkProfits)) setLoading(true);
  };
  // 获取数据的方法
  const getStakeByLimit = useCallback(async () => {
    if (myStaking && Number(pagesLinkProfits) && stakeByLimitLod) {
      try {
        // 获取到数据
        const list = await myStaking.getStakeByLimit(
          address,
          pagesLinkProfits,
          10
        );
        setPages("0");
        // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
        if (list.length) {
          const id = Number(formatUnits(list[list.length - 1].id, 0)) - 1 || 0
          if (id <= 0) {
            setPages("0");
          } else {
            setPages(id.toString());
          }
          // 放入data
          setDataStakeByLimit([...dataStakeByLimit, ...list]);
        } else {
          setPages("0");
        }
      } catch (e) {
        console.log("useGetStakeByLimit", e);
      }
      setLoading(false);
    }
  }, [address, dataStakeByLimit, myStaking, pagesLinkProfits, stakeByLimitLod]);

  useEffect(() => {
    getStakeByLimit();
  }, [getStakeByLimit]);
  return {
    dataStakeByLimit,
    pagesLinkProfits,
    first,
    stakeByLimitLod,
    setHistoryLinkProfits,
    setDataStakeByLimit,

  };
};

export default useGetStakeByLimit;
